<template>
  <div id="data-list-list-view" class="data-list-container">

    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <new :warehouses="warehouses" @add="addDataSideBar" :isSidebarActive="newSidebar" @closeSidebar="newSidebar=false"/>

    <vx-card collapse="false" ref="filterCard" title="Filtros" class="user-list-filters mb-8" collapse-action
             refresh-content-action @refresh="resetFilters">
      <h5 class="mt-5 mb-2">Filtrar por fecha</h5>
      <div class="vx-row">
        <div class="vx-col md:w-1/2">
          <datepicker :language="es" label="Desde" v-model="startDate" id="startDate"
                      v-validate="'required'" name="startDate"></datepicker>
        </div>
        <div class="vx-col md:w-1/2">
          <datepicker :language="es" label="Gasta" v-model="endDate" id="endDate"
                      v-validate="'required'" name="startDate"></datepicker>
        </div>
        <div class="vx-col mt-2">
          <vs-button @click="filterByDate" class="mr-6 vs-align-right">Filtrar</vs-button>
        </div>
      </div>
    </vx-card>

    <vs-table v-model="selected" ref="table" pagination :max-items="itemsPerPage" search
              :data="list">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- Action - dropnow -->
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4">

            <div
                class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
              <span class="mr-2">Acciones</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item @click="sidebarData= selected,  updateSidebar= true">
                            <span class="flex items-center">
                              <feather-icon icon="EditIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Editar</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="exportToExcel">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar</span>
                            </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <!-- Add new -->
          <div
              class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
              @click="newSidebar=true">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4"/>
            <span class="ml-2 text-base text-primary">Agregar</span>
          </div>
        </div>
        <!-- Items per page -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{
                currentPage * itemsPerPage - (itemsPerPage - 1)
              }} - {{ list.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : list.length }} de {{
                queriedItems
              }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=30">
              <span>30</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th>Local</vs-th>
        <vs-th>Fecha de liquidación</vs-th>
        <vs-th>Ingresos con factura</vs-th>
        <vs-th>Ingresos con boletas</vs-th>
        <vs-th>Gastos con factura</vs-th>
        <vs-th>Gastos con boletas</vs-th>
        <vs-th>Gastos con recibos</vs-th>
        <vs-th>Gastos SC</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <vs-td>
            <p>{{ tr.warehouse.name }}</p>
          </vs-td>

          <vs-td>
            <p>{{ tr.date | date(true) }}</p>
          </vs-td>

          <vs-td>
            <p>{{
                Intl.NumberFormat('en-IN', {style: 'currency', currency: 'PEN'}).format(tr.inputInvoice)
              }}</p>
          </vs-td>

          <vs-td>
            <p>{{
                Intl.NumberFormat('en-IN', {style: 'currency', currency: 'PEN'}).format(tr.inputTicket)
              }}</p>
          </vs-td>

          <vs-td>
            <p>{{
                Intl.NumberFormat('en-IN', {style: 'currency', currency: 'PEN'}).format(tr.outputInvoice)
              }}</p>
          </vs-td>

          <vs-td>
            <p>{{
                Intl.NumberFormat('en-IN', {style: 'currency', currency: 'PEN'}).format(tr.outputTicket)
              }}</p>
          </vs-td>

          <vs-td>
            <p>{{
                Intl.NumberFormat('en-IN', {style: 'currency', currency: 'PEN'}).format(tr.outputReceipt)
              }}</p>
          </vs-td>

          <vs-td>
            <p>{{
                Intl.NumberFormat('en-IN', {style: 'currency', currency: 'PEN'}).format(tr.outputSC)
              }}</p>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import New from './New'
import Datepicker from 'vuejs-datepicker'

import {db, Timestamp} from '@/firebase/firebaseConfig'

import {es} from 'vuejs-datepicker/dist/locale'

const _ = require('lodash')

export default {
  components: {
    New,
    Datepicker
  },
  data() {
    return {
      selected: null,
      itemsPerPage: 10,
      isMounted: false,
      list: [],
      warehouses: [],
      initProgress: false,

      // Data Sidebar
      newSidebar: false,
      updateSidebar: false,
      sidebarData: {},
      startDate: null,
      endDate: null,
      es: es
    }
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.list.length
    }
  },
  async created() {
    try {
      await this.loadSettlements()
      await this.loadWarehouses()
    } catch (e) {
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  mounted() {
    this.isMounted = true
  },
  methods: {
    async loadSettlements() {
      //Get settlements
      this.initProgress = true
      const querySnapshot = await db.collection("settlements").orderBy('createdAt', 'desc').get()
      querySnapshot.forEach((doc) => {
        let obj = {
          id: doc.id,
          ...doc.data()
        }
        obj.date = doc.data().date.toDate()
        this.list.push(obj)
      })
    },
    /**
     * Get warehouses
     * @returns {Promise<void>}
     */
    async loadWarehouses() {
      this.warehouses = []
      const querySnapshot = await db.collection("warehouses").orderBy('createdAt', 'desc').get()
      querySnapshot.forEach((doc) => {
        let warehouse = {
          id: doc.id,
          ...doc.data()
        }
        this.warehouses.push(warehouse)
      })
    },
    /**
     * Add data of sidebar
     * */
    addDataSideBar(o) {
      this.list.unshift(o)
    },
    /**
     * Update data of sidebar
     * */
    updateDataSideBar(o) {
      const index = this.list.findIndex((d) => d.id === o.id)
      Object.assign(this.list[index], o)
    },
    /**
     * Reset filters
     * @returns {Promise<void>}
     */
    async resetFilters() {
      this.initProgress = true
      this.list = []
      this.startDate = null
      this.endDate = null
      await this.loadSettlements()
      this.$refs.filterCard.removeRefreshAnimation()
      this.initProgress = false
    },
    /**
     * Filter by date
     */
    async filterByDate() {
      try {
        if (this.startDate && this.endDate) {
          this.initProgress = true
          this.list = []
          // Set start date
          this.startDate.setHours(0)
          this.startDate.setMinutes(0)
          // Set end date
          this.endDate.setHours(23)
          this.endDate.setMinutes(59)

          this.startDate.set
          const starTimes = Timestamp.fromDate(this.startDate)
          const endTimes = Timestamp.fromDate(this.endDate)
          const querySnapshot = await db.collection('settlements')
              .where('date', '>=', starTimes)
              .where('date', '<=', endTimes).get()
          querySnapshot.forEach((doc) => {
            this.list.push({
              id: doc.id,
              ...doc.data(),
              date: doc.data().date.toDate()
            })
          })
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Export to excel
     */
    exportToExcel() {
      import('@/vendor/Export2Excel').then(excel => {
        const list = _.cloneDeep(this.list)
        const headerVal = ['date', 'inputInvoice', 'inputTicket', 'outputInvoice', 'outputTicket', 'outputReceipt', 'outputSC']
        const headerTitle = ['Fecha', 'Ingresos con factura', 'Ingresos con boletas', 'Gastos con facutra', 'Gastos con boletas', 'Gastos con recibos', 'Gastos sc']
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: 'Listado de liquidaciones',
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
