<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Registrar liquidación</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!--Date-->
        <div>
          <label class="vs-select--label" for="dateOfIssue">Fecha de liquidación</label>
          <datepicker :language="es" label="Fecha de liquidación" v-model="document.date" id="dateOfIssue"
                      v-validate="'required'" name="date"></datepicker>
          <span class="text-danger text-sm"
                v-show="errors.has('date')">{{ errors.first('date') }}</span>
        </div>
        <!-- Local -->
        <div class="mt-5">
          <label class="text-sm opacity-75">Local</label>
          <v-select value="value" label="name" :options="warehouses" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.warehouse" class="mb-4 md:mb-0" name="warehouse" v-validate="'required'"/>

          <span class="text-danger text-sm"
                v-show="errors.has('warehouse')">{{ errors.first('warehouse') }}</span>
        </div>
        <!-- Input Invoice -->
        <div class="mt-5">
          <vs-input type="number" label="Ingresos con facturas" v-model.number="document.inputInvoice"
                    class="w-full" name="inputInvoice"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('inputInvoice')">{{ errors.first('inputInvoice') }}</span>
        </div>

        <!-- Input ticket -->
        <div class="mt-5">
          <vs-input type="number" label="Ingresos con boletas" v-model.number="document.inputTicket"
                    class="w-full" name="inputTicket"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('inputTicket')">{{ errors.first('inputTicket') }}</span>
        </div>

        <!-- Output invoice -->
        <div class="mt-5">
          <vs-input type="number" label="Gastos con facturas" v-model.number="document.outputInvoice"
                    class="w-full" name="outputInvoice"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('outputInvoice')">{{ errors.first('outputInvoice') }}</span>
        </div>

        <!-- Output ticket -->
        <div class="mt-5">
          <vs-input type="number" label="Gastos con boletas" v-model.number="document.outputTicket"
                    class="w-full" name="outputTicket"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('outputTicket')">{{ errors.first('outputTicket') }}</span>
        </div>

        <!-- Output receipt -->
        <div class="mt-5">
          <vs-input type="number" label="Gastos con recibos" v-model.number="document.outputReceipt"
                    class="w-full" name="outputReceipt"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('outputReceipt')">{{ errors.first('outputReceipt') }}</span>
        </div>

        <!-- Output sc -->
        <div class="mt-5">
          <vs-input type="number" label="Gastos SC" v-model.number="document.outputSC"
                    class="w-full" name="outputSC"
                    v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('outputSC')">{{ errors.first('outputSC') }}</span>
        </div>

        <!--Document-->
        <div class="mt-5">
          <vs-input @blur="document.document= trimString(document.document)" label="Documento"
                    v-model="document.document"
                    class="w-full" name="document"
                    v-validate="'required|max:250'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('document')">{{ errors.first('document') }}</span>
        </div>
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="confirmSubmit" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from "../../mixins/trimString"
import Datepicker from 'vuejs-datepicker'

import {db, FieldValue, auth} from '@/firebase/firebaseConfig'
import {es} from 'vuejs-datepicker/dist/locale'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    warehouses: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    Datepicker
  },
  mixins: [trimString],
  data() {
    return {
      document: {},

      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      deleteImageFlag: false,
      es: es
    }
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        this.document = {}
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid() {
      return !this.errors.any()
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Confirm submit
     * */
    async confirmSubmit() {
      const result = await this.$validator.validateAll()
      if (result) {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: '¡Atención!',
          text: `Luego de guardar no podrás editarla. ¿Deseas guardar la liquidación?`,
          accept: this.submitData,
          cancel: null,
          parameters: null,
          acceptText: 'Guardar',
          cancelText: 'Cancelar'
        })
      }
    },
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData() {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          this.progress = true
          let obj = {
            ...this.document,
            state: true
          }
          const doc = await db.collection('settlements').add({
            ...obj,
            createdAt: FieldValue.serverTimestamp(),
            uid: auth.currentUser.uid
          })
          obj.id = doc.id
          this.$emit('add', obj)
          this.$emit('closeSidebar')
          this.progress = false
          this.$vs.notify({
            color: 'success',
            title: 'Marca',
            text: 'Marca creada correctamente.'
          })
        }
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52004;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
